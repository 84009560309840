<template src="./index.html" />

<script>
import {
  BButton, BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow, BContainer, BImg,
  BFormInput, BFormDatepicker, BFormGroup, BForm, BFormFile, BTab, BTabs, BOverlay, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import { HAS_LEARN_PARTY } from '@/const/selection'
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from 'flatpickr/dist/l10n'
import { Flag } from '@/const/flag'

export default {
  name: 'Index',
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BContainer,
    BImg,
    BFormInput,
    BFormDatepicker,
    BFormGroup,
    BForm,
    vSelect,
    BFormFile,
    BTab,
    BTabs,
    BOverlay,
    VueGoodTable,
    BFormSelect,
    BPagination,
    flatPickr,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      mainProps: {
        fluidGrow: true,
        height: 200,
      },
      student: {
        student_code: null,
      },
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      scholarshipColumns: [
        {
          label: 'Khóa học',
          field: 'course',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập khóa học',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semester',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập học kỳ',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tên học bổng',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên học bổng',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      awardColumns: [
        {
          label: 'Tên khen thưởng',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên khen thưởng',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      disciplineColumns: [
        {
          label: 'Tên kỷ luật',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên kỷ luật',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày kỷ luật',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày kỷ luật',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      policyColumns: [
        {
          label: 'Đối tượng trợ cấp',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập đối tượng trợ cấp',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      allowanceColumns: [
        {
          label: 'Trợ cấp sư phạm',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập trợ cấp sư phạm',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      config: {
        wrap: true,
        enableTime: false,
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        locale: FlatpickrLanguages.vn,
        static: false,
      },
      files: [],
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      studentInfo: 'curriculumVitae/studentInfo',
      curriculumVitaeLearnings: 'curriculumVitae/curriculumVitaeLearnings',
      curriculumVitaeScholarships: 'curriculumVitae/curriculumVitaeScholarships',
      curriculumVitaeAwards: 'curriculumVitae/curriculumVitaeAwards',
      curriculumVitaeDisciplines: 'curriculumVitae/curriculumVitaeDisciplines',
      curriculumVitaePolicies: 'curriculumVitae/curriculumVitaePolicies',
      curriculumVitaeAllowances: 'curriculumVitae/curriculumVitaeAllowances',
      genders: 'dropdown/genders',
      allFactEthnics: 'curriculumVitae/allFactEthnics',
      allFactDisabilities: 'curriculumVitae/allFactDisabilities',
      allFactReligions: 'curriculumVitae/allFactReligions',
      allFactProvinces: 'curriculumVitae/allFactProvinces',
      allFactDistricts: 'curriculumVitae/allFactDistricts',
      allFactWards: 'curriculumVitae/allFactWards',
      allFactNationalities: 'curriculumVitae/allFactNationalities',
      studentExport: 'curriculumVitae/studentExport',
      avatar: 'student/avatar',
    }),
    hasLearnPartySelect() {
      return HAS_LEARN_PARTY
    },
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    classId() {
      const { programmes, portalProgrammeId } = this.user
      if (!portalProgrammeId || programmes.length === 0) return null
      const found = programmes.find(element => element.id === portalProgrammeId)
      return found ? found.classId : null
    },
  },
  watch: {
    'student.province_code': async function (val) {
      this.isLoading = true
      try {
        await this.getDistricts({ province_code: val })
        if (this.allFactDistricts.length > 0) {
          this.student.district_code = this.allFactDistricts[0].code
        } else {
          this.student.district_code = null
          this.student.ward_code = null
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    'student.district_code': async function (val) {
      this.isLoading = true
      try {
        await this.getWards({ province_code: this.student.province_code, district_code: val })
        this.student.ward_code = this.allFactWards.length > 0 ? this.allFactWards[0].code : null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
  async created() {
    this.isLoading = false
    try {
      await this.getInitData()
      await Promise.all([
        this.getFactEthnics(),
        this.getFactDisabilities(),
        this.getFactReligions({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
        this.getFactProvinces({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
        this.getFactNationalities({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
        this.getGenders(),
        this.getDistricts({ province_code: this.student.province_code }),
        this.getWards({
          province_code: this.student.province_code,
          district_code: this.student.district_code,
        }),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    // handle even
    ...mapActions({
      exportStudent: 'curriculumVitae/exportStudent',
      updateStudentInfo: 'curriculumVitae/updateStudentInfo',
      uploadAvatar: 'student/uploadAvatar',
      getStudentInfo: 'curriculumVitae/getStudentInfo',
      getGenders: 'dropdown/getGenders',
      getFactEthnics: 'curriculumVitae/getFactEthnics',
      getFactDisabilities: 'curriculumVitae/getFactDisabilities',
      getFactReligions: 'curriculumVitae/getFactReligions',
      getFactProvinces: 'curriculumVitae/getFactProvinces',
      getFactNationalities: 'curriculumVitae/getFactNationalities',
      getDistricts: 'curriculumVitae/getDistricts',
      getWards: 'curriculumVitae/getWards',
      getAvatarById: 'student/getAvatarById',
    }),

    async onSubmit() {
      this.isLoading = false
      try {
        const response = await this.updateStudentInfo(this.student)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    async uploadFile(e) {
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File đang bị trống!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

      files.forEach(file => formData.append('files', file))
      const response = await this.uploadAvatar(formData)
      if (response.isSuccessful) {
        this.showToast('Cập nhật avatar thành công', 'CheckIcon', 'success')
        await this.getInitData()
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
      this.$refs.file.reset()
    },
    async getInitData() {
      if (!this.classId) {
        this.showToast('Vui lòng gán chương trình đào tạo cho học viên!', 'InfoIcon', 'warning')
        return
      }
      this.isLoading = true
      try {
        await Promise.all([
          this.getStudentInfo({
            studentId: this.user.studentId,
            classId: this.classId,
          }),
          this.getAvatarById(this.user.studentId),
        ])
        this.student = { ...this.studentInfo }
        console.log(this.avatar)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
    async exportExcel() {
      await this.exportStudent(this.filter)
      const a = document.createElement('a')
      document.body.appendChild(a)
      const blob = new Blob([this.studentExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = `ho_so_sinh_vien_${this.student.student_name}.xlsx`
      a.click()
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
